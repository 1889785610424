import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CoreLink } from '../library/link';

import './link.scss';

export default function Link({
	href, to, onClick, className, color, children, hideBorder, rel,
	segmentLabel, segmentConfig, segmentProperties, target, productClick,
	inheritFont
}) {
	return (
		<CoreLink
			href={href}
			to={to}
			onClick={onClick}
			rel={rel}
			target={target}
			className={
				classNames(className, color, { 'hide-border': hideBorder, inheritFont })
			}
			segmentLabel={segmentLabel}
			segmentConfig={segmentConfig}
			segmentProperties={segmentProperties}
			productClick={productClick}
		>
			<span className={classNames("link-content", color)}>
				{children}
			</span>
		</CoreLink>
	);
}

Link.propTypes = {
	/** color of Link */
	color: PropTypes.oneOf(['white', 'yellow', 'gray']),
	/** hides link underline */
	hideBorder: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	href: PropTypes.string,
	to: PropTypes.string,
	onClick: PropTypes.func,
	rel: PropTypes.string,
	segmentLabel: PropTypes.string.isRequired,
	segmentConfig: PropTypes.object,
	segmentProperties: PropTypes.object,
	/** Fires the Product Clicked event when paired with ecommerce tracking */
	productClick: PropTypes.bool,
	target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),
	inheritFont: PropTypes.bool
};
