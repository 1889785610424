import PropTypes from 'prop-types';
import React from 'react';
import { compose, defaultProps, branch } from 'recompose';
import lazyLoadEnhancer from 'decorators/lazyLoad';
import { CoreImage } from 'components/core/library/image';

export const enhance = compose(
	defaultProps({ lazyLoad: true }),
	branch(({ lazyLoad }) => lazyLoad, lazyLoadEnhancer)
);

function Image({
	alt, className, src, flexImage, role, titleIcon, height, width, onClick
}) {
	return (
		<CoreImage
			alt={alt}
			src={src}
			role={role}
			flexImage={flexImage}
			titleIcon={titleIcon}
			className={className}
			height={height}
			onClick={onClick}
			width={width}
		/>
	);
}

Image.propTypes = {
	/** The alt tag for the image */
	alt: PropTypes.string,
	/** The path to the image source */
	src: PropTypes.string.isRequired,
	/** transforms the image into a background that is set to cover the container */
	flexImage: PropTypes.bool,
	/** optional image class name */
	className: PropTypes.string,
	/** Make image vertically centered with text with right margin */
	titleIcon: PropTypes.bool,
	/** accessibility role */
	role: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	onClick: PropTypes.func
};

export default enhance(Image);
