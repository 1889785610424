/* eslint-disable max-len */
import React from 'react';
import { Switch } from 'react-router-dom';
import { compose, withProps, branch, renderComponent } from 'recompose';
import { PageTypes } from '@sparefoot/segment-react';
import { RouteWithQueryAndProps as SFRoute } from 'decorators';
import { connect } from 'react-redux';
import { removeUrlParam } from 'lib/helpers';
// Import Code Split Bundles
import {
	HomePage,
	FacilityPage,
	BookingConfirmation,
	CarStoragePage,
	BusinessStoragePage,
	PSPLandingPage,
	MovingTruckCLPPage,
	UnitPage,
	LegalConsumerPage,
	LegalConsumerPrivacyPage,
	LegalConsumerPaymentPage,
	LegalClientLTVPage,
	LegalReviewsPage,
	NewFourOhFourPage,
	UnsubscribePage,
	SizeGuidePage,
	SelfStoragePage,
	ClimateControlledPage,
	BoatStoragePage,
	StateLandingPage,
	MovingTruckPage,
	MovingSuppliesPage,
	PortableStoragePage,
	ReviewPage,
	SearchPage,
	RebateClaimPage,
	RebateStatusPage,
	RVStoragePage,
	MoveInConfirmStatusPage,
	MoveInIncentivePage,
	VehicleLengthPage,
	PaidTemplatePage,
	EmailAuthPage
} from 'pages/Bundles';

// Import Page Actions
import {
	getBookingPage,
	getHomepage,
	getUnsubscribePage,
	getLegalPage,
	getRebateClaimPage,
	getRebateStatusPage,
	getMoveInIncentivePage,
	getMovingSuppliesPage,
	getPortableStoragePage,
	getPortableStorageCityLandingPage,
	getUnitPage,
	getStateLandingPage,
	getReviewPage,
	getSupplementalReviewData,
	getPSPLandingPage,
	getStorageLandingPage,
	getMovingTruckPage,
	getMovingTruckCityLandingPage,
	getMoveInConfirmStatusPage,
	vehicleLengthPageSuccess,
	getPaidTemplatePage
} from 'store/pages/actions';

import { getFacilityPage } from 'store/facilities/actions';

import {
	getSearchPage,
	getCityLandingPage,
	getVehicleLandingPage,
	getAmenityLandingPage,
	getNeighborhoodLandingPage,
	getStorageDealsPage,
	getFiveByFiveLandingPage,
	getTenByTenLandingPage,
} from 'store/search/actions';



export const staticRoutes = [
	{
		path: '/',
		exact: true,
		component: HomePage,
		actions: { getHomepage },
		loadData: (props) => props.actions.getHomepage(),
	},
	{
		path: '/unsubscribe(.html)?',
		exact: true,
		actions: { getUnsubscribePage },
		component: UnsubscribePage,
		loadData: (props) => props.actions.getUnsubscribePage(),
	},
	{
		path: '/reservation/(cancellation|confirmation)/:confirmationCode([A-Za-z0-9]+)(.html)?',
		exact: true,
		actions: { getBookingPage },
		component: compose(
			connect(state => ({
				statusCode: state.pageMeta.statusCode
			})),
			branch(
				props => [401, 403].includes(props.statusCode), // Unauthorized or forbidden booking
				compose(
					withProps(props => {
						const { email, ...restQueryParams } = props.queryParams || {};
						// Update URL to remove email param
						if (email) {
							removeUrlParam('email');
						}
						return {
							queryParams: restQueryParams
						};
					}),
					renderComponent(EmailAuthPage)
				)
			)
		)(BookingConfirmation),
		loadData: (props) => props.actions.getBookingPage(
			props.match.params.confirmationCode,
			props.queryParams,
		)
	},
	{
		path: '/legal/consumer(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalConsumerPage,
		loadData: (props) => props.actions.getLegalPage('legal'),
	},
	{
		path: '/legal/privacy(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalConsumerPrivacyPage,
		loadData: (props) => props.actions.getLegalPage('legal'),
	},
	{
		path: '/legal/:pageType(client|ltv)(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalClientLTVPage,
		loadData: (props) => props.actions.getLegalPage('legal'),
	},
	{
		path: '/legal/:pageType(client|ltv)/:version([0-9]+)(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalClientLTVPage,
		loadData: (props) => props.actions.getLegalPage('legal'),
	},
	{
		path: '/legal/consumer/payment(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalConsumerPaymentPage,
		loadData: (props) => props.actions.getLegalPage('legal'),
	},
	{
		path: '/legal/reviewstoc(.html)?',
		exact: true,
		actions: { getLegalPage },
		component: LegalReviewsPage,
		loadData: (props) => props.actions.getLegalPage('reviewstoc'),
	},
	{
		path: '/rebate(.html)?',
		exact: true,
		component: RebateClaimPage,
		actions: { getRebateClaimPage },
		loadData: (props) => props.actions.getRebateClaimPage(),
	},
	{
		path: '/rebate/status(.html)?',
		exact: true,
		component: RebateStatusPage,
		actions: { getRebateStatusPage },
		loadData: (props) => props.actions.getRebateStatusPage(),
	},
	{
		path: '/rebate/move-in/:confirmationCode([A-Za-z0-9]+)/:offerId([A-Za-z0-9]+)(.html)?',
		exact: true,
		component: MoveInIncentivePage,
		actions: { getMoveInIncentivePage },
		loadData: (props) =>
			props.actions.getMoveInIncentivePage(
				props.match.params.confirmationCode,
				props.match.params.offerId
			),
	},
	{
		path: '/moving-supplies(.html)?',
		exact: true,
		actions: { getMovingSuppliesPage },
		component: MovingSuppliesPage,
		loadData: (props) => props.actions.getMovingSuppliesPage(),
	},
	{
		path: '/portable-storage(.html)?',
		exact: true,
		actions: { getPortableStoragePage },
		component: PortableStoragePage,
		loadData: (props) => props.actions.getPortableStoragePage(),
	},
	{
		path: '/storage-unit-size-guide(.html)?',
		exact: true,
		actions: { getPSPLandingPage },
		component: SizeGuidePage,
		loadData: (props) => props.actions.getPSPLandingPage('size'),
	},

	{
		path: '/portable-storage/:city([A-Za-z0-9-\\.]+)-:state([A-Za-z]{2})(.html)?',
		exact: true,
		actions: { getPortableStorageCityLandingPage },
		component: PortableStoragePage,
		loadData: (props) =>
			props.actions.getPortableStorageCityLandingPage(
				props.match.params.city,
				props.match.params.state
			),
	},
	{
		path: '/facility/:facilityId(\\d{3,})/unit/:unitId(\\d{3,})(.html)?',
		exact: true,
		actions: { getUnitPage },
		component: UnitPage,
		loadData: (props) =>
			props.actions.getUnitPage(
				props.match.params.unitId,
				props.match.params.facilityId
			),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/*-:facilityId(\\d{3,})(/reserve)?/:unitId(\\d+)(.html)?',
		exact: true,
		actions: { getUnitPage },
		component: UnitPage,
		loadData: (props) =>
			props.actions.getUnitPage(
				props.match.params.unitId,
				props.match.params.facilityId
			),
	},
	{
		path: '/search(.html)?',
		exact: true,
		actions: { getSearchPage },
		component: SearchPage,
		pageType: PageTypes.SEARCH,
		key: 'search',
		loadData: (props) => props.actions.getSearchPage(props.queryParams),
	},
	{
		path: '/review(.html)?',
		exact: true,
		actions: { getReviewPage, getSupplementalReviewData },
		component: ReviewPage,
		loadData: (props) =>
			Promise.all([
				props.actions.getReviewPage(),
				props.actions.getSupplementalReviewData(props.queryParams),
			]),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage(.html)?',
		exact: true,
		actions: { getCityLandingPage },
		component: SearchPage,
		pageType: PageTypes.CITY_LANDING,
		key: 'search',
		loadData: (props) =>
			props.actions.getCityLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/:vehicle(car|boat|rv)-storage(.html)?',
		exact: true,
		actions: { getVehicleLandingPage },
		component: SearchPage,
		pageType: PageTypes.VEHICLE_LANDING,
		key: 'search',
		loadData: (props) =>
			props.actions.getVehicleLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/:amenity(climate-controlled|24-hour-access|drive-up-storage-units)(.html)?',
		exact: true,
		actions: { getAmenityLandingPage },
		component: SearchPage,
		pageType: PageTypes.AMENITY_LANDING,
		key: 'search',
		loadData: (props) =>
			props.actions.getAmenityLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	{
		path: '/storage-deals(.html)?', // Update STORAGE_DEALS_SEARCH_PATH if this changes
		exact: true,
		component: SearchPage,
		actions: { getStorageDealsPage },
		pageType: PageTypes.STORAGE_DEALS_LANDING,
		key: 'search',
		loadData: ({ actions, queryParams }) =>
			actions.getStorageDealsPage(queryParams),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/5x5-storage-units(.html)?',
		exact: true,
		actions: { getFiveByFiveLandingPage },
		component: SearchPage,
		pageType: 'city_5x5_landing_page',
		key: 'search',
		loadData: (props) =>
			props.actions.getFiveByFiveLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/10x10-storage-units(.html)?',
		exact: true,
		actions: { getTenByTenLandingPage },
		component: SearchPage,
		pageType: 'city_10x10_landing_page',
		key: 'search',
		loadData: (props) =>
			props.actions.getTenByTenLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/*-:facilityId(\\d{3,})(.html)?',
		exact: true,
		actions: { getFacilityPage },
		component: FacilityPage,
		loadData: (props) =>
			props.actions.getFacilityPage(
				props.match.params.facilityId,
				props.match.params.city,
				props.match.params.state
			),
	},
	{
		path: '/(facility|facilities)/:facilityId(\\d{3,})(.html)?',
		exact: true,
		actions: { getFacilityPage },
		component: FacilityPage,
		loadData: (props) =>
			props.actions.getFacilityPage(props.match.params.facilityId),
	},
	{
		path: '/:city-:state([A-Za-z]{2})-self-storage/:neighborhood([A-Za-z-0-9]+)(.html)?',
		exact: true,
		actions: { getNeighborhoodLandingPage },
		component: SearchPage,
		pageType: PageTypes.NEIGHBORHOOD_LANDING,
		key: 'search',
		loadData: (props) =>
			props.actions.getNeighborhoodLandingPage(
				props.match.params,
				props.queryParams
			),
	},
	// PSPs
	{
		path: '/climate-controlled-storage(.html)?',
		exact: true,
		actions: { getPSPLandingPage },
		component: ClimateControlledPage,
		loadData: (props) => props.actions.getPSPLandingPage('climate'),
	},
	{
		path: '/boat-storage(.html)?',
		exact: true,
		actions: { getPSPLandingPage },
		component: BoatStoragePage,
		loadData: (props) => props.actions.getPSPLandingPage('boat'),
	},
	{
		path: '/business-storage(.html)?',
		exact: true,
		actions: { getPSPLandingPage },
		component: BusinessStoragePage,
		loadData: (props) => props.actions.getPSPLandingPage('business'),
	},
	{
		path: '/car-storage(.html)?',
		exact: true,
		actions: { getPSPLandingPage },
		component: CarStoragePage,
		loadData: (props) => props.actions.getPSPLandingPage('car'),
	},
	{
		path: '/rv-storage(.html)?',
		exact: true,
		component: RVStoragePage,
		actions: { getPSPLandingPage },
		loadData: ({ actions }) => actions.getPSPLandingPage('rv'),
	},
	{
		path: '/:storageType(college|wine)-storage(.html)?',
		exact: true,
		component: PSPLandingPage,
		actions: { getPSPLandingPage },
		loadData: ({ actions, match }) =>
			actions.getPSPLandingPage(match.params.storageType),
	},
	{
		path: '/:state([A-Za-z-]+)-storage(.html)?',
		exact: true,
		component: StateLandingPage,
		actions: { getStateLandingPage },
		loadData: ({ actions, match }) =>
			actions.getStateLandingPage(match.params.state),
	},
	{
		path: '/storage(.html)?',
		exact: true,
		actions: { getStorageLandingPage },
		component: SelfStoragePage,
		loadData: (props) => props.actions.getStorageLandingPage(),
	},
	{
		path: '/moving-truck-rental(.html)?',
		exact: true,
		actions: { getMovingTruckPage },
		component: MovingTruckPage,
		loadData: (props) =>
			props.actions.getMovingTruckPage(props.queryParams),
	},
	{
		path: '/moving-truck-rental/:city([A-Za-z0-9-\\.]+)-:state([A-Za-z]{2})(.html)?',
		exact: true,
		actions: { getMovingTruckCityLandingPage },
		component: MovingTruckCLPPage,
		loadData: (props) =>
			props.actions.getMovingTruckCityLandingPage(props.match.params),
	},
	{
		path: '/reservation/confirm-:moveInStatus(.*move-in)/:confirmationCode([A-Za-z0-9]+)(.html)?',
		exact: true,
		component: MoveInConfirmStatusPage,
		actions: { getMoveInConfirmStatusPage },
		loadData: (props) =>
			props.actions.getMoveInConfirmStatusPage(
				props.match.params.confirmationCode,
				props.match.params.moveInStatus
			),
	},
	{
		path: '/tools/vehicle-length(.html)?',
		exact: true,
		component: VehicleLengthPage,
		actions: { vehicleLengthPageSuccess },
		loadData: (props) => props.actions.vehicleLengthPageSuccess(),
	},
	{
		path: '/paid/:paidPageUri?', // requires camelcase syntax to fire action & handle /paid/ or /paid/some-route-here dynamically
		component: PaidTemplatePage,
		actions: {getPaidTemplatePage},
		loadData: (props) => props.actions.getPaidTemplatePage(props.queryParams, props.match.url),
	},
	{
		component: NewFourOhFourPage,
	},
];

export default function Routes() {
	return (
		<Switch>
			{staticRoutes.map((route, i) => (
				<SFRoute
					key={route.key || i}
					path={route.path}
					exact={route.exact}
					strict={route.strict}
					component={route.component}
					pageType={route.pageType}
				/>
			))}
		</Switch>
	);
}
