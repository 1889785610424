import { normalize } from 'normalizr';
import omit from 'lodash/omit';
import get from 'lodash/get';
import axios from 'axios';
import { RequestError } from 'lib/request/error';
import { BotDetectedError } from 'lib/errors';

export const API = 'CALL_API';

export function callApi(
	endpoint,
	options = {
		method: 'GET',
		headers: {},
		query: {},
		data: {},
	},
	schema,
	adapter,
	state
) {
	return new Promise((resolve, reject) =>
		axios({
			method: options.method,
			url: endpoint,
			data: options.data,
			params: options.query,
			headers: options.headers,
			validateStatus: (status) => status < 400,
		})
			.then((response) => {
				let returnData = get(response, 'data') || {};
				if (typeof adapter === 'function') {
					returnData = adapter(returnData, state);
				}
				if (schema) {
					returnData = {
						...normalize(returnData, schema),
						seo: returnData.seo || {},
					};
				}
				return resolve(returnData);
			})
			.catch((err) => {
				if (err.response?.status === 401) {
					reject({
						...err,
						status: 401,
						data: err.response.data
					});
					return;
				}
				const message = get(err.response, 'data.message');
				switch (message) {
					case "Please prove you're not a robot.":
						reject(new BotDetectedError(message));
						break;
					default:
						reject(new RequestError(err));
				}
			})
	);
}

export default (store) =>
	(next) =>
	(action = {}) => {
		const callAPI = action[API];

		if (typeof callAPI === 'undefined') {
			return next(action);
		}

		let { endpoint } = callAPI;
		const { schema, types, method, data, query, meta, adapter } = callAPI;
		const [requestType, successType, failureType] = types;
		const state = store.getState();

		if (typeof endpoint === 'function') {
			endpoint = endpoint(state);
		}

		function actionWith(actionData) {
			return omit({ ...action, ...actionData }, API);
		}

		// Fire Request Action
		next(actionWith({ type: requestType, data, query }));

		const { user = {}, app } = state;
		
		// Build Request Options
		const options = {
			method: method || 'GET',
			data,
			query,
			headers:
			 user.cookies?.jwt ? 
			 { Authorization: `Bearer ${user.cookies.jwt}`} : {},
		};

		// Add Headers For Server Calls
		if (process.env.RENDER_ENV === 'server') {
			options.headers = {
				'app-id': app.id,
				'site-id': user.siteId,
				'visit-id': user.visitId,
				'traffic-source-id': user.traffic_source_id,
				cid: user.cid,
				ctid: user.ctid,
				'x-is-bot':
					user.visitId === 'bot' || user.visitId === 'error' ? 1 : 0,
				'x-sparefoot-ip': user.user_ip,
				token: process.env.SFAPIGW_TOKEN,
				'x-sparefoot-service': 'reactfoot',
				'user-agent': 'SFAPIGW ServiceClient',
			};

			if (user.cookies.jwt) {
				options.headers.Authorization = 
				`Bearer ${user.cookies.jwt}`;
				
			}
		}


		const host = app.sfapigw_host_internal || app.sfapigw_host;
		endpoint = `${host}${endpoint}`;
		return callApi(endpoint, options, schema, adapter, state)
			.then((payload) =>
				next(
					actionWith({
						type: successType,
						meta,
						payload,
					})
				)
			)
			.catch((error) =>
				next(
					actionWith({
						type: failureType,
						meta,
						error,
					})
				)
			);
	};
