/**
 * Synchronous Route Exports
 * In development all routes are loaded synchronously
 * make sure all split bundles are also added to AsyncBundles
 */
/* eslint-disable global-require, max-len */
import universal from 'react-universal-component';

export const HomePage = universal(import(/* webpackChunkName: "HomePage" */'./HomePage/HomePage'));
export const FacilityPage = universal(import(/* webpackChunkName: "FacilityPage" */'./FacilityPage/FacilityPage'));
export const SearchPage = universal(import(/* webpackChunkName: "SearchPage" */'./SearchPage/SearchPage'));
export const PSPLandingPage = universal(import(/* webpackChunkName: "PSPLandingPage" */'./PSPLandingPage/PSPLandingPage'));
export const BookingConfirmation = universal(import(/* webpackChunkName: "BookingConfirmation" */'./BookingConfirmation/BookingConfirmation'));
export const MovingTruckCLPPage = universal(import(/* webpackChunkName: "MovingTruckCLPPage" */'./MovingTruckCLPPage/MovingTruckCLPPage'));
export const UnitPage = universal(import(/* webpackChunkName: "UnitPage" */'./UnitPage/UnitPage'));
export const LegalConsumerPage = universal(import(/* webpackChunkName: "LegalConsumerPage" */'./LegalPages/LegalConsumerPage'));
export const LegalConsumerPrivacyPage = universal(import(/* webpackChunkName: "LegalConsumerPrivacyPage" */'./LegalPages/LegalConsumerPrivacyPage'));
export const LegalClientLTVPage = universal(import(/* webpackChunkName: "LegalClientLTVPage" */'./LegalPages/LegalClientLTVPage'));
export const LegalConsumerPaymentPage = universal(import(/* webpackChunkName: "LegalConsumerPaymentPage" */'./LegalPages/LegalConsumerPaymentPage'));
export const LegalReviewsPage = universal(import(/* webpackChunkName: "LegalReviewsPage" */'./LegalPages/LegalReviewsPage'));
export const FourOhFourPage = universal(import(/* webpackChunkName: "FourOhFourPage" */'./FourOhFourPage/FourOhFourPage'));
export const NewFourOhFourPage = universal(import(/* webpackChunkName: "NewFourOhFourPage" */'./NewFourOhFourPage/NewFourOhFourPage'));
export const UnsubscribePage = universal(import(/* webpackChunkName: "UnsubscribePage" */'./UnsubscribePage/UnsubscribePage'));
export const StateLandingPage = universal(import(/* webpackChunkName: "StateLandingPage" */'./StateLandingPage/StateLandingPage'));
export const MovingTruckPage = universal(import(/* webpackChunkName: "MovingTruckPage" */'./MovingTruckPage/MovingTruckPage'));
export const MovingSuppliesPage = universal(import(/* webpackChunkName: "MovingSuppliesPage" */'./MovingSuppliesPage/MovingSuppliesPage'));
export const PortableStoragePage = universal(import(/* webpackChunkName: "PortableStoragePage" */'./PortableStoragePage/PortableStoragePage'));
export const ReviewPage = universal(import(/* webpackChunkName: "ReviewPage" */'./ReviewPage/ReviewPage'));
export const RebateClaimPage = universal(import(/* webpackChunkName: "RebateClaimPage" */'./RebateClaimPage/RebateClaimPage'));
export const RebateStatusPage = universal(import(/* webpackChunkName: "RebateStatusPage" */'./RebateStatusPage/RebateStatusPage'));
export const MoveInConfirmStatusPage = universal(import(/* webpackChunkName: "MoveInConfirmStatusPage" */'./MoveInConfirmStatusPage/MoveInConfirmStatusPage'));
export const MoveInIncentivePage = universal(import(/* webpackChunkName: "MoveInIncentivePage" */'./MoveInIncentivePage/MoveInIncentivePage'));
export const VehicleLengthPage = universal(import(/* webpackChunkName: "VehicleLengthPage" */'./VehicleLengthPage/VehicleLengthPage'));
export const SizeGuidePage = universal(import(/* webpackChunkName: "SizeGuidePage" */'./SizeGuidePage/SizeGuidePage'));
export const CarStoragePage = universal(import(/* webpackChunkName: "CarStoragePage" */'./CarStoragePage/CarStoragePage'));
export const BusinessStoragePage = universal(import(/* webpackChunkName: "BusinessStoragePage" */'./BusinessStoragePage/BusinessStoragePage'));
export const SelfStoragePage = universal(import(/* webpackChunkName: "SelfStoragePage" */'./SelfStoragePage/SelfStoragePage'));
export const ClimateControlledPage = universal(import(/* webpackChunkName: "ClimateControlledPage" */'./ClimateControlledPage/ClimateControlledPage'));
export const BoatStoragePage = universal(import(/* webpackChunkName: "BoatStoragePage" */'./BoatStorage/BoatStoragePage'));
export const RVStoragePage = universal(import(/* webpackChunkName: "RVStoragePage" */'./RVStoragePage/RVStoragePage'));
export const PaidTemplatePage = universal(import(/* webpackChunkName: "PaidTemplatePage" */'./PaidTemplatePage/PaidTemplatePage'));
export const EmailAuthPage = universal(import(/* webpackChunkName: "EmailAuthPage" */'./EmailAuthPage/EmailAuthPage'))