import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from '@sparefoot/react-core';
import { Text } from 'components/core/Text';
import { ChevronDown } from 'components/svg';
import MenuItem from './MenuItem';
import withCollapsibleMenuHandlers from './withCollapsibleMenuHandlers';

const enhance = withCollapsibleMenuHandlers;

export class AccordionMenu extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.toggleCheckbox = this.toggleCheckbox.bind(this);
		this.menuId = `menu-${this.props.id}`;
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event) {
		// if event.target is outside of the dropdown, close the dropdown
		const { className } = event.target;

		const element = document.getElementById(this.menuId);
		// Prevents links in menu or dropdown icons from counting as a "click outside"
		const isLinkOrText = !!className && typeof className === 'string' && className?.includes('inner-accordion-safe');

		if (!isLinkOrText) {
			if (element.checked) {
				element.checked = false;
			}
		}
	}

	toggleCheckbox() {
		this.props.handleClick(this.props.text);
	}

	render() {
		const { innerLinks, text, rightAlign, inline, isActive } = this.props;

		return (
			<div
				ref={this.bindRef}
				className={classnames('accordion-menu', {
					'menu-secondary': rightAlign
				})}
			>
				{/* Uses "checkbox trick to make the header-accordion-button work for :checked css logic https://css-tricks.com/the-checkbox-hack/ */}
				<label
					htmlFor={this.menuId}
					className="header-accordion-button ph-link inner-accordion-safe"
				>
					<Text strong size="tiny" className="button-content">
						{text}
					</Text>
					{innerLinks && <ChevronDown className="chevron-down" />}

					<Checkbox
						id={this.menuId}
						segmentLabel={`${isActive ? 'close' : 'open'} ${text}`}
						onChange={this.toggleCheckbox}
						className="visually-hidden"
						checked={isActive}
					/>
				</label>

				{innerLinks && (
					<div className="accordion-links">
						{innerLinks.map(({ href, text: innerText }) => (
							<MenuItem
								href={href}
								text={innerText}
								key={innerText}
								inline={inline}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}

AccordionMenu.propTypes = {
	inline: PropTypes.bool,
	innerLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
	rightAlign: PropTypes.bool,
	text: PropTypes.string,
	id: PropTypes.number,
	isActive: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default enhance(AccordionMenu);
