import { PageTypes } from '@sparefoot/segment-react';
import { handleActions, combineActions } from 'redux-actions';
import { get, set, isEmpty } from 'lodash';
import {
	getIndexViewPixel,
	getStaticViewPixel,
	getBookingViewPixel,
	getPaidLandingViewPixel,
	getPSPLandingViewPixel,
	getErrorViewPixel,
	getUnitViewPixel,
	getSearchViewPixel,
	getSearchLandingViewPixel,
	getFacilityViewPixel,
	getHiddenFacilityViewPixel,
	getLandingViewPixel,
} from 'utils/tracking';
import { isFacilityInactive } from 'utils/facility';
import { PAGES } from 'store/pages/actions';
import { SEARCH } from 'store/search/actions';
import { FACILITIES } from 'store/facilities/actions';
// TODO: move
import { isInactiveFacilityError } from 'store/facilities/reducers';
import { PAGE_META } from './actions';

export const initialState = {
	phone: {},
	pageType: null,
	statusCode: 200,
	tags: {},
	pixelData: {},
	isLandingPage: false,
	noindex: false,
	redirectURL: null,
};

export function fourOhFourPageMeta(state) {
	return {
		...state,
		pageType: 'errorPage404',
		statusCode: 404,
		tags: { title: 'Something bad happened | SpareFoot' },
		pixelData: {
			pagePixelSrc: getErrorViewPixel('error404'),
		},
	};
}

// Tells the SSR to redirect instead of rendering the app
export function redirectPageMeta(state, redirectURL = '/') {
	return {
		...state,
		statusCode: 301,
		redirectURL,
	};
}

export default handleActions(
	{
		[PAGE_META.SERVER_RENDER_ERROR]: (state, action) => ({
			...state,
			pageType: 'errorPage500',
			statusCode: 500,
			tags: { title: 'Something bad happened | SpareFoot' },
			error: action.error,
			pixelData: {
				pagePixelSrc: getErrorViewPixel('error500'),
			},
		}),
		[PAGE_META.SET]: (state, action) => {
			let metaDescription = get(
				action,
				'payload.pageMeta.tags.meta.structuredData.description'
			);
			if (metaDescription) {
				// Remove non-language characters
				metaDescription = metaDescription.replace(
					/[^a-zA-Z0-9\t\n]/g,
					' '
				);
				set(
					action,
					'payload.pageMeta.tags.meta.structuredData.description',
					metaDescription
				);
			}
			return {
				...state,
				...action.payload.pageMeta,
			};
		},
		[PAGE_META.SET_PAGE_TYPE]: (state, action) => ({
			...state,
			pageType: action.payload.pageType,
		}),
		[PAGE_META.SET_PHONE]: (state, action) => ({
			...state,
			phone: action.payload.phone,
		}),
		[PAGE_META.SET_TAGS]: (state, action) => ({
			...state,
			tags: action.payload.tags,
			noindex: action.payload.noindex,
		}),
		[PAGES.HOMEPAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.HOME,
			pixelData: {
				pagePixelSrc: getIndexViewPixel(),
			},
		}),
		[PAGES.UNSUBSCRIBE_PAGE_SUCCESS]: (state, action) => ({
			...state,
			noindex: true,
			phone: action.payload.phone,
			pageType: PageTypes.UNSUBSCRIBE,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[PAGES.BOOKING_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.result.phone,
			pageType: PageTypes.CONFIRMATION,
			pixelData: {
				pagePixelSrc: getBookingViewPixel(
					action.payload.result.booking
				),
			},
		}),
		[PAGES.BOOKING_PAGE_FAILURE]: (state, action) => {
			if ([401, 403].includes(action.error?.status)) {
				return {
					...state,
					statusCode: action.error.status,
					booking: action.error.data || {},
					isAuthorized: false
				};
			}
			return {
				...state,
				statusCode: action.error.status || 404,
			}
		},
		[PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS]: (state, action) => ({
			...state,
			noindex: true,
			phone: action.payload.result.phone,
			pageType: action.payload.result.pageType,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_FAILURE]: (state) =>
			fourOhFourPageMeta(state),
		[PAGES.LEGAL_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.LEGAL,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[PAGES.PPC_LANDING_SUCCESS]: (state, action) => ({
			...state,
			noindex: true,
			phone: action.payload.phone,
			pageType: PageTypes.PPC,
			pixelData: {
				pagePixelSrc: getPaidLandingViewPixel('toplvl'),
			},
			isLandingPage: true,
		}),
		[PAGES.REBATE_CLAIM_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.REBATE,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[PAGES.REBATE_STATUS_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.REBATE_STATUS,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[PAGES.MOVING_SUPPLIES_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.MOVING_SUPPLIES_PSP,
			tags: {
				...state.tags,
				title: 'Find Moving Supplies & Moving Boxes | SpareFoot',
				meta: {
					...state.tags.meta,
					description:
						'Make your move easier with exclusive deals on boxes and other moving supplies including mattress covers, moving blankets, bubble wrap and tape.', // eslint-disable-line max-len
				},
				links: [
					...(state.tags.links || []),
					{
						rel: 'canonical',
						content: `${action.meta.baseURL}/moving-supplies.html`,
					},
				],
			},
			pixelData: {
				pagePixelSrc: getPSPLandingViewPixel('supplies'),
			},
		}),
		[PAGES.PORTABLE_STORAGE_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.PORTABLE_STORAGE_PSP,
			tags: {
				...state.tags,
				links: [
					...(state.tags.links || []),
					{
						rel: 'canonical',
						href: `${action.meta.baseURL}/portable-storage.html`,
					},
				],
			},
			pixelData: {
				pagePixelSrc: getPSPLandingViewPixel('portableStorage'),
			},
		}),
		[PAGES.PORTABLE_STORAGE_CLP_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.phone,
			pageType: PageTypes.PORTABLE_STORAGE_CLP,
			tags: {
				...state.tags,
				links: [
					...(state.tags.links || []),
					{
						rel: 'canonical',
						content: `${action.meta.baseURL}${action.payload.canonicalURL}`,
					},
				],
			},
			pixelData: {
				pagePixelSrc: getLandingViewPixel({
					content: action.payload.content,
				}),
			},
		}),
		[PAGES.PORTABLE_STORAGE_CLP_FAILURE]: (state) =>
			fourOhFourPageMeta(state),
		[PAGES.UNIT_PAGE_SUCCESS]: (state, action) => {
			const result = get(action, 'payload.result');
			return {
				...state,
				phone: get(result, 'phone'),
				pageType: PageTypes.RESERVE,
				pixelData: {
					pagePixelSrc: getUnitViewPixel(
						get(result, 'unit'),
						get(result, 'facility')
					),
				},
			};
		},
		[PAGES.UNIT_PAGE_FAILURE]: (state) => fourOhFourPageMeta(state),

		[PAGES.PAID_LANDING_TEMPLATE_SUCCESS]: (state, action) => {
			return {
				...state,
				storageType: action.meta.storageType,
				location: action.meta.location,
				pageType: `PaidLanding-${action.meta.storageType}`,
				pixelData: getPaidLandingViewPixel(action.meta.storageType),
				noindex: true, // TODO: REMOVE THIS ONCE WE GO LIVE WITH THE PAGE (IF SEO SIGNS OFF)
				tags: {
					...state.tags,
					// TODO: Update meta content for paid landing pages
					title: `${action.meta.storageType} Storage | SpareFoot`,
					meta: {
						...state.tags.meta,
						// TODO: Update meta content for paid landing pages
						description: `This is a demo descripstion using ${action.meta.storageType} and ${action.meta.location} as an example`, // eslint-disable-line max-len
					},
					links: [
						...(state.tags.links || []),
						{
							rel: 'canonical',
							content: `${action.meta.baseURL}${action.meta.url}`, // verify with SEO
						},
					],
				},
			};
		},

		[PAGES.PAID_LANDING_TEMPLATE_FAILURE]: (state) =>
			fourOhFourPageMeta(state),

		[SEARCH.PAGE_SUCCESS]: (state, { payload }) => ({
			...state,
			phone: payload.result.phone,
			noindex: get(payload, 'result.meta.noindex', true),
			pageType: PageTypes.SEARCH,
			pixelData: {
				pagePixelSrc: getSearchViewPixel(
					payload.entities.search[payload.result.search]
				),
			},
		}),
		[SEARCH.SEARCH_LANDING_SUCCESS]: (state, { payload }) => {
			const alternateCity = get(
				payload,
				'result.content.lowPerformingAlt.alternateCity',
				null
			);
			const alternateState = get(
				payload,
				'result.content.lowPerformingAlt.alternateState',
				null
			);
			if (!!alternateCity && !!alternateState) {
				return redirectPageMeta(
					state,
					`/${alternateCity}-${alternateState}-self-storage.html`
				);
			}

			// no alt = not a low performer: go to normal CLP
			return {
				...state,
				phone: payload.result.phone,
				pageType: payload.result.location.subPageType,
				pixelData: {
					pagePixelSrc: getSearchLandingViewPixel(payload),
				},
			};
		},
		[SEARCH.SUCCESS]: (state, { meta, payload }) => {
			const { result } = payload;

			if (result.seo.canonicalWithQueryParams) {
				return {
					...state,
					tags: {
						...state.tags,
						links: [
							{
								rel: 'canonical',
								content: `${meta.baseURL}${result.seo.canonicalWithQueryParams}`,
							},
						],
					},
				};
			}

			return state;
		},
		[SEARCH.PAGE_FAILURE]: (state, { error }) => ({
			...state,
			pixelData: {
				pagePixelSrc: getSearchViewPixel(),
			},
			// Only passes through 410 and 404 error codes, ignores 500s. This will render the ErrorPage with a
			// 410 or 404 response
			statusCode:
				error && (error.status === 410 || error.status === 404)
					? error.status
					: state.statusCode,
		}),
		[SEARCH.SEARCH_LANDING_FAILURE]: (state, { error }) => ({
			...state,
			// Only passes through 410 and 404 error codes, ignores 500s. This will render the ErrorPage with a
			// 410 or 404 response. 410s are for the Low Performing Cities Geo Footprint SEO Optimization (SF-6912)
			statusCode:
				error && (error.status === 410 || error.status === 404)
					? error.status
					: state.statusCode,
		}),
		[PAGES.REVIEW_PAGE_SUCCESS]: (state, { payload }) => ({
			...state,
			noindex: true,
			phone: payload.phone,
			pageType: PageTypes.REVIEW,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[FACILITIES.FACILITY_PAGE_SUCCESS]: (state, { payload, meta = {} }) => {
			if (!get(payload, 'result.facility')) {
				return fourOhFourPageMeta(state);
			}

			const facility =
				payload.entities.facilities[payload.result.facility];
			const { city, state: facilityState } = meta;
			if (city && facilityState) {
				const cityState = `${city}-${facilityState}`;
				const facilityUrl = get(facility, 'url.facility');

				if (facilityUrl && !facilityUrl.includes(cityState)) {
					return redirectPageMeta(state, facilityUrl);
				}
			}

			return {
				...state,
				phone: payload.result.phone,
				pageType: facility.isOutOfNetwork
					? PageTypes.OON_FACILITY
					: PageTypes.FACILITY,
				pixelData: {
					pagePixelSrc: isFacilityInactive(facility)
						? getHiddenFacilityViewPixel(payload.result)
						: getFacilityViewPixel(payload.result),
				},
			};
		},

		[FACILITIES.FACILITY_PAGE_FAILURE]: (state, { error }) => {
			const canonicalUrl = get(error, 'data.context.url.facility', null);
			if (!isEmpty(canonicalUrl)) {
				return redirectPageMeta(state, canonicalUrl);
			}

			if (isInactiveFacilityError(error)) {
				const phone = get(error, 'data.context.phone');
				return {
					...state,
					noindex: true,
					tags: {
						...state.tags,
						title: 'SpareFoot',
					},
					pixelData: {
						pagePixelSrc: getFacilityViewPixel({
							facility: error.data.context.facilityId,
						}),
					},
					phone,
				};
			}
			return fourOhFourPageMeta(state);
		},
		[PAGES.STATE_LANDING_PAGE_SUCCESS]: (state, action) => {
			if (!get(action, 'payload.location.state')) {
				return fourOhFourPageMeta(state);
			}
			return {
				...state,
				phone: action.payload.phone,
				pageType: PageTypes.STATE_LANDING,
				pixelData: {
					pagePixelSrc: getLandingViewPixel({
						content: action.payload.content,
					}),
				},
			};
		},
		[PAGES.STATE_LANDING_PAGE_FAILURE]: (state) =>
			fourOhFourPageMeta(state),
		[PAGES.PSP_PAGE_SUCCESS]: (state, { meta, payload }) => ({
			...state,
			phone: payload.phone,
			pageType: `${meta.page}LandingPage`,
			pixelData: {
				pagePixelSrc: getPSPLandingViewPixel(meta.storageType),
			},
			tags: {
				...state.tags,
				links: [
					...(state.tags.links || []),
					{
						rel: 'canonical',
						href: `${meta.baseURL}/${
							get(payload, 'meta.canonical') ||
							`${meta.storageType}-storage.html`
						}`,
					},
				],
			},
		}),
		[combineActions(
			PAGES.CAR_PSP_PAGE_SUCCESS,
			PAGES.RV_PSP_PAGE_SUCCESS,
			PAGES.BOAT_PSP_PAGE_SUCCESS,
			PAGES.STORAGE_PAGE_SUCCESS
		)]: (state, { meta, payload }) => ({
			...state,
			phone: payload.result.phone,
			pageType: `${meta.page}LandingPage`,
			pixelData: {
				pagePixelSrc: getPSPLandingViewPixel(
					meta.storageType || 'storage'
				),
			},
			tags: {
				...state.tags,
				links: [
					{
						rel: 'canonical',
						href: meta.storageType
							? `${meta.baseURL}/${meta.storageType}-storage.html`
							: `${meta.baseURL}/storage.html`,
					},
				],
			},
			noindex: meta.noindex || state.noindex,
		}),
		[PAGES.MOVING_TRUCK_PAGE_SUCCESS]: (state, action) => ({
			...state,
			phone: action.payload.result.phone,
			pageType: PageTypes.MOVING_TRUCK_PSP,
			pixelData: {
				pagePixelSrc: getPSPLandingViewPixel('movingTruck'),
			},
		}),
		[PAGES.MOVING_TRUCK_PAGE_FAILURE]: (state) => fourOhFourPageMeta(state),
		[PAGES.MOVING_TRUCK_CLP_SUCCESS]: (state, { payload }) => {
			const truckResults =
				payload.entities.search[payload.result.truckResults];
			return {
				...state,
				phone: payload.result.phone,
				pageType: PageTypes.MOVING_TRUCK_CLP,
				pixelData: {
					pagePixelSrc: getLandingViewPixel({
						result: truckResults,
						content: payload.result.content,
					}),
				},
			};
		},
		[PAGES.MOVING_TRUCK_CLP_FAILURE]: (state) => fourOhFourPageMeta(state),
		[PAGES.MOVEIN_INCENTIVE_PAGE_SUCCESS]: (state, action) => ({
			...state,
			tags: { title: 'SpareFoot' },
			phone: action.payload.result.phone,
			pageType: action.payload.result.pageType,
		}),
		[PAGES.VEHICLE_LENGTH_PAGE_SUCCESS]: (state) => ({
			...state,
			noindex: true,
			tags: {
				title: 'SpareFoot: Vehicle Length Tool',
			},
			phone: {
				nationalSparephone: '',
				nationalSparephoneUnformatted: '',
			},
			pageType: PageTypes.VEHICLE_LENGTH,
			pixelData: {
				pagePixelSrc: getStaticViewPixel(),
			},
		}),
		[SEARCH.STORAGE_DEALS_FAILURE]: (state) => fourOhFourPageMeta(state),
		[SEARCH.STORAGE_DEALS_SUCCESS]: (state, { payload }) => ({
			...state,
			tags: {
				...state.tags,
				title: payload.result.meta.title,
				meta: {
					...state.tags.meta,
					description: payload.result.meta.description,
				},
			},
			pageType: PageTypes.STORAGE_DEALS_LANDING,
			pixelData: {
				pagePixelSrc: getLandingViewPixel({
					result: payload.entities.search[payload.result.search],
					content: payload.result.content,
				}),
			},
		}),
	},
	initialState
);
