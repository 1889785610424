import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Link } from 'components/core/Link';
import { Text } from 'components/core/Text';
import { Phone } from 'components/svg';

import './phoneNumber.scss';

export function PhoneText({
	color, strong, size, phoneNumber
}) {
	return (
		<Text
			color={color}
			strong={strong}
			size={size}
		>
			<span className="number">
				{phoneNumber}
			</span>
		</Text>
	);
}

PhoneText.propTypes = {
	phoneNumber: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['tiny', 'small', 'medium', 'standard', 'large', 'extra-large', 'huge', 'giant']),
	strong: PropTypes.bool,
	color: PropTypes.string
};

export function PhoneNumber({
	className,
	clickable,
	color,
	hideBorder,
	size,
	strong,
	hideIcon,
	phoneNumber,
	unformattedPhoneNumber
}) {
	return (
		<div className={classnames('phone-number', className)}>
			{!hideIcon &&
				<Phone size={size} />
			}
			{!!clickable &&
				<Link
					color={color}
					hideBorder={hideBorder}
					href={`tel:${unformattedPhoneNumber}`}
					segmentLabel="click to call"
				>
					<PhoneText
						color={color}
						strong={strong}
						size={size}
						phoneNumber={phoneNumber}
					/>
				</Link>
			}
			{!clickable &&
				<PhoneText
					color={color}
					strong={strong}
					size={size}
					phoneNumber={phoneNumber}
				/>
			}
		</div>
	);
}

PhoneNumber.propTypes = {
	/** The phone number to display - e.g. (888) 777-6666 */
	phoneNumber: PropTypes.string.isRequired,
	unformattedPhoneNumber: PropTypes.string,
	size: PropTypes.oneOf(['tiny', 'small', 'standard', 'medium', 'large', 'extra-large', 'huge', 'giant']),
	strong: PropTypes.bool,
	color: PropTypes.string,
	hideIcon: PropTypes.bool,
	clickable: PropTypes.bool,
	hideBorder: PropTypes.bool,
	className: PropTypes.string
};

PhoneNumber.defaultProps = {
	strong: true,
	size: 'small',
	hideIcon: false,
	clickable: false,
	hideBorder: false
};


export default PhoneNumber;
