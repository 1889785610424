import { API } from 'store/middleware/api';
import { setUploadUrl } from 'store/fileUpload/actions';
import { createAction } from 'redux-actions';
import { bookingConfirmationSchema } from 'store/schema/booking';

export const BOOKINGS = {
	BOOKING_REQUEST: 'SFDC/PAGES/BOOKING_REQUEST',
	BOOKING_SUCCESS: 'SFDC/PAGES/BOOKING_SUCCESS',
	BOOKING_FAILURE: 'SFDC/PAGES/BOOKING_FAILURE',
	CREATE_REQUEST: 'SFDC/BOOKINGS/CREATE_REQUEST',
	CREATE_SUCCESS: 'SFDC/BOOKINGS/CREATE_SUCCESS',
	CREATE_FAILURE: 'SFDC/BOOKINGS/CREATE_FAILURE',
	TRACKING_SUCCESS: 'SFDC/BOOKINGS/TRACKING_SUCCESS',
	UPDATE_REQUEST: 'SFDC/BOOKINGS/UPDATE_REQUEST',
	UPDATE_SUCCESS: 'SFDC/BOOKINGS/UPDATE_SUCCESS',
	UPDATE_FAILURE: 'SFDC/BOOKINGS/UPDATE_FAILURE',
	EMAIL_REQUEST: 'SFDC/BOOKINGS/EMAIL_REQUEST',
	EMAIL_SUCCESS: 'SFDC/BOOKINGS/EMAIL_SUCCESS',
	EMAIL_FAILURE: 'SFDC/BOOKINGS/EMAIL_FAILURE',
	CANCEL_REQUEST: 'SFDC/BOOKINGS/CANCEL_REQUEST',
	CANCEL_SUCCESS: 'SFDC/BOOKINGS/CANCEL_SUCCESS',
	CANCEL_FAILURE: 'SFDC/BOOKINGS/CANCEL_FAILURE',
	RECENT_BOOKING_REQUEST: 'SFDC/BOOKINGS/RECENT_BOOKING_REQUEST',
	RECENT_BOOKING_SUCCESS: 'SFDC/BOOKINGS/RECENT_BOOKING_SUCCESS',
	RECENT_BOOKING_FAILURE: 'SFDC/BOOKINGS/RECENT_BOOKING_FAILURE',
	FROM_CONFIRMATION_CODE_REQUEST:
		'SFDC/BOOKINGS/FROM_CONFIRMATION_CODE_REQUEST',
	FROM_CONFIRMATION_CODE_SUCCESS:
		'SFDC/BOOKINGS/FROM_CONFIRMATION_CODE_SUCCESS',
	FROM_CONFIRMATION_CODE_FAILURE:
		'SFDC/BOOKINGS/FROM_CONFIRMATION_CODE_FAILURE',
	OFFER_REDEMPTION_REQUEST: 'SFDC/BOOKINGS/OFFER_REDEMPTION_REQUEST',
	OFFER_REDEMPTION_SUCCESS: 'SFDC/BOOKINGS/OFFER_REDEMPTION_SUCCESS',
	OFFER_REDEMPTION_FAILURE: 'SFDC/BOOKINGS/OFFER_REDEMPTION_REQUEST',
	UPLOAD_URL_REQUEST: 'SFDC/BOOKINGS/UPLOAD_URL_REQUEST',
	UPLOAD_URL_SUCCESS: 'SFDC/BOOKINGS/UPLOAD_URL_SUCCESS',
	UPLOAD_URL_FAILURE: 'SFDC/BOOKINGS/UPLOAD_URL_FAILURE',
	SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST:
		'SFDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST',
	SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS:
		'SFDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS',
	SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE:
		'SFDC/BOOKINGS/SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE',
	SMS_OPT_OUT_REQUEST: 'SFDC/BOOKINGS/SMS_OPT_OUT_REQUEST',
	SMS_OPT_OUT_SUCCESS: 'SFDC/BOOKINGS/SMS_OPT_OUT_SUCCESS',
	SMS_OPT_OUT_FAILURE: 'SFDC/BOOKINGS/SMS_OPT_OUT_FAILURE',
	LEAD_REQUEST: 'SFDC/BOOKINGS/LEAD_REQUEST',
	LEAD_SUCCESS: 'SFDC/BOOKINGS/LEAD_SUCCESS',
	LEAD_FAILURE: 'SFDC/BOOKINGS/LEAD_FAILURE',
};

export const trackingSuccess = createAction(BOOKINGS.TRACKING_SUCCESS);

export function createBooking(body) {
	return (dispatch, getState) => {
		const { siteId, traffic_source_id } = getState().user; // eslint-disable-line camelcase
		const data = { ...body, siteId, trafficSourceId: traffic_source_id };

		return dispatch({
			[API]: {
				types: [
					BOOKINGS.CREATE_REQUEST,
					BOOKINGS.CREATE_SUCCESS,
					BOOKINGS.CREATE_FAILURE,
				],
				method: 'POST',
				endpoint: '/bookings',
				data,
			},
		});
	};
}

export function createLead(data) {
	return {
		[API]: {
			types: [
				BOOKINGS.LEAD_REQUEST,
				BOOKINGS.LEAD_SUCCESS,
				BOOKINGS.LEAD_FAILURE,
			],
			method: 'POST',
			endpoint: '/consumer/create-lead',
			data,
		},
	};
}

export function cancelReservation(confirmationCode, userId) {
	return {
		[API]: {
			types: [
				BOOKINGS.CANCEL_REQUEST,
				BOOKINGS.CANCEL_SUCCESS,
				BOOKINGS.CANCEL_FAILURE,
			],
			method: 'POST',
			endpoint: `/booking/delete/${confirmationCode}`,
			data: {
				userId,
			},
		},
	};
}

export function updateReservation(confirmationCode, formData) {
	return {
		[API]: {
			types: [
				BOOKINGS.UPDATE_REQUEST,
				BOOKINGS.UPDATE_SUCCESS,
				BOOKINGS.UPDATE_FAILURE,
			],
			method: 'POST',
			endpoint: `/booking/update/${confirmationCode}`,
			data: formData,
		},
	};
}

export function getBookingResponseForAuth(confirmationCode, queryParams) {
	return {
		[API]: {
			types: [
				BOOKINGS.FROM_CONFIRMATION_CODE_REQUEST,
				BOOKINGS.FROM_CONFIRMATION_CODE_SUCCESS,
				BOOKINGS.FROM_CONFIRMATION_CODE_FAILURE,
			],
			method: 'GET',
			endpoint: `/booking/confirmationCode/${confirmationCode}`,
			query: {
				bookingEmail: queryParams?.email,
				isExperimentAuth: queryParams?.isExperimentAuth,
			},
			schema: bookingConfirmationSchema,
		},
	};
}

export function sendReservationEmail(confirmationCode) {
	return {
		[API]: {
			types: [
				BOOKINGS.EMAIL_REQUEST,
				BOOKINGS.EMAIL_SUCCESS,
				BOOKINGS.EMAIL_FAILURE,
			],
			method: 'POST',
			endpoint: `/booking/email/${confirmationCode}`,
		},
	};
}

export function getRecentBookings() {
	return {
		[API]: {
			types: [
				BOOKINGS.RECENT_BOOKING_REQUEST,
				BOOKINGS.RECENT_BOOKING_SUCCESS,
				BOOKINGS.RECENT_BOOKING_FAILURE,
			],
			method: 'GET',
			endpoint: '/booking/recent/',
		},
	};
}

export function getOfferRedemptionRequestFileUploadUrl(fileName) {
	return (dispatch, getState) => {
		const offerId = getState().bookings.offer.id;
		return dispatch({
			[API]: {
				types: [
					BOOKINGS.UPLOAD_URL_REQUEST,
					BOOKINGS.UPLOAD_URL_SUCCESS,
					BOOKINGS.UPLOAD_URL_FAILURE,
				],
				method: 'GET',
				endpoint: `/booking/offer/${offerId}/documentUploadUrl`,
			},
		}).then((response) => {
			const {
				payload: { uploadUrl, key },
			} = response;
			return dispatch(setUploadUrl(fileName, uploadUrl, key));
		});
	};
}

export function submitOfferRedemptionRequest(offerId, itemValue, documentKeys) {
	return {
		[API]: {
			types: [
				BOOKINGS.OFFER_REDEMPTION_REQUEST,
				BOOKINGS.OFFER_REDEMPTION_SUCCESS,
				BOOKINGS.OFFER_REDEMPTION_FAILURE,
			],
			method: 'POST',
			endpoint: `/booking/offers/${offerId}/redemptionRequest`,
			data: {
				itemValue,
				documentKeys,
			},
		},
	};
}

export function reservationSmsOptInAndSendConfirmation(
	phone,
	confirmationCode,
	userId
) {
	return {
		[API]: {
			types: [
				BOOKINGS.SMS_OPT_IN_AND_SEND_CONFIRMATION_REQUEST,
				BOOKINGS.SMS_OPT_IN_AND_SEND_CONFIRMATION_SUCCESS,
				BOOKINGS.SMS_OPT_IN_AND_SEND_CONFIRMATION_FAILURE,
			],
			method: 'POST',
			endpoint: `/sms/optIn/sendConfirmation/${confirmationCode}`,
			data: {
				campaignName: 'reservation',
				phone,
				confirmationCode,
				userId,
			},
		},
	};
}

export function reservationSmsOptOut(phone, confirmationCode, userId) {
	return {
		[API]: {
			types: [
				BOOKINGS.SMS_OPT_OUT_REQUEST,
				BOOKINGS.SMS_OPT_OUT_SUCCESS,
				BOOKINGS.SMS_OPT_OUT_FAILURE,
			],
			method: 'POST',
			endpoint: '/sms/optOut',
			data: {
				campaignName: 'reservation',
				phone,
				confirmationCode,
				userId,
			},
		},
	};
}
