import { has } from 'lodash';
import { handleActions, combineActions } from 'redux-actions';
import mergeEntities from 'utils/store/mergeEntities';
import { BOOKINGS } from 'store/bookings/actions';
import { REVIEWS } from 'store/reviews/actions';
import { PAGES } from 'store/pages/actions';

export const initialState = {
	loading: false,
	error: false,
	errorMessage: null,
	byId: {},
	recentBookings: [],
	trackedBooking: false
};

const mergeBookings = (state, action) => {
	return {
	...state,
	loading: false,
	byId: has(action, 'payload.entities.bookings') ?
		mergeEntities(state.byId, action.payload.entities.bookings) :
		state.byId
	}
};

const reducer = handleActions({
	[combineActions(
		PAGES.BOOKING_PAGE_SUCCESS,
		PAGES.MOVE_IN_CONFIRM_STATUS_PAGE_SUCCESS,
		BOOKINGS.UPDATE_SUCCESS,
		REVIEWS.GET_REVIEW_FACILITY_SUCCESS,
		BOOKINGS.FROM_CONFIRMATION_CODE_SUCCESS
	)]: mergeBookings,
	[BOOKINGS.CREATE_REQUEST]: (state) => ({
		...state,
		trackedBooking: false
	}),
	[BOOKINGS.TRACKING_SUCCESS]: (state, action) => ({
		...state,
		trackedBooking: action.payload.confirmationCode
	}),
	[BOOKINGS.UPDATE_REQUEST]: (state) => ({
		...state,
		loading: true
	}),
	[BOOKINGS.UPDATE_FAILURE]: (state) => ({
		...state,
		loading: false
	}),
	[BOOKINGS.RECENT_BOOKING_SUCCESS]: (state, action) => ({
		...state,
		recentBookings: action.payload.result.recentBookings,
		byId: {
			...state.byId,
			...action.payload.entities.bookings
		}
	}),
	[BOOKINGS.BOOKING_SUCCESS]: (state, action) => ({
		...state,
		byId: {
			...state.byId,
			[action.payload.result.confirmationCode]: action.payload.result
		}
	}),
	[PAGES.MOVEIN_INCENTIVE_PAGE_SUCCESS]: (state, action) => ({
		...mergeBookings(state, action),
		offer: action.payload.result.offer
	})
}, initialState);


export default reducer;
