import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { branch, renderComponent } from 'recompose';
import { Link } from 'components/core/Link';
import { Text } from 'components/core/Text';
import AccordionMenuItem from './AccordionMenuItem';

const enhance = branch(
	({ href }) => !href,
	renderComponent(AccordionMenuItem)
);

export function MenuItem({
	href, text, rightAlign
}) {
	return (
		<Link
			href={href}
			className={classnames('header-link', { 'menu-secondary': rightAlign })}
			segmentLabel={text}
			target={href.includes('storageguide') || href.includes('blog.sparefoot') ? "_blank" : "_self"}
		>
			<Text size="tiny">{text}</Text>
		</Link>
	);
}

MenuItem.propTypes = {
	href: PropTypes.string,
	text: PropTypes.string,
	rightAlign: PropTypes.bool
};

export default enhance(MenuItem);
